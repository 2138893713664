import React, {useState} from "react";

function AddDropDown({addDropDown}) {
	const [name, setName] = useState('');
	const [multiple, setMultiple] = useState(false);

	function onSubmit(evt) {
		evt.preventDefault();
		try {
			addDropDown(name, multiple);
			setName('');
		}
		catch (e) {
			console.error(e);
		}
	}

	return (
		<form onSubmit={onSubmit} className="d-flex justify-content-between">
			<div>
				<input type="text" className={"form-control"} placeholder={"Name"} required={true} value={name} onChange={(evt) => {
					setName(evt.target.value);
				}} onKeyDown={evt => {
					evt.stopPropagation();
				}}/>
			</div>
			<div className="form-check">
				<input className="form-check-input" type="checkbox" checked={multiple} onChange={(evt) => {
					setMultiple(evt.target.checked);
				}} id="multiple" />
				<label className="form-check-label" htmlFor="multiple">
					Multiple
				</label>
			</div>
			<div>
				<button className={"btn btn-primary btn-sm"} type={"submit"} >Add dropdown</button>
			</div>
		</form>
	);
}

export default AddDropDown;
