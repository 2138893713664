import usePlaybackRate from "./usePlaybackRate";

function PlaybackRate() {
	const {startSpeedingUp, startSlowingDown, end, resetPlaybackRate, playbackRate} = usePlaybackRate();
	return (
		<>
			<button type={"button"} onMouseDown={startSpeedingUp} onMouseUp={end} className={"btn btn-warning"}>Speed up</button>
			<button type={"button"} onClick={resetPlaybackRate} className={"btn btn-warning"} disabled={playbackRate === 1}>Reset</button>
			<button type={"button"} onMouseDown={startSlowingDown} onMouseUp={end} disabled={playbackRate <= 0} className={"btn btn-warning"}>Slow down</button>
		</>
	);
}

export default PlaybackRate;
