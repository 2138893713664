import {useContext, useState} from "react";
import {regionsContext} from "../../../contexts/regions";

function DropDown({dropDown, region}) {
	const {updateRegion} = useContext(regionsContext);
	const [size, setSize] = useState(1);

	function onFocus() {
		if (dropDown.multiple) {
			setSize(dropDown.options.length); // make select bigger according to the options
		}
	}
	function onBlur() {
		setSize(1); // set select to the default size (minimise)
	}

	function onChange(evt) {
		if (dropDown.multiple) {
			updateRegion({
				id: region.id,
				[dropDown.name]: [...evt.target.options].filter(value => value.selected).map(value => value.value)
			});
		}
		else {
			updateRegion({
				id: region.id,
				[dropDown.name]: evt.target.value
			});
		}
	}

	const value = region[dropDown.name] || (dropDown.multiple ?[]:''); // default empty array or string because I won't bother setting them in the initialState

	return (
		<select style={{width: 'unset'}} className={"form-select"} multiple={dropDown.multiple} size={size} onFocus={onFocus} onBlur={onBlur}
				value={value} onChange={onChange}>
			{!dropDown.multiple && <option value={""}>Select an option</option>}
			{dropDown.options.map(value => {
				return <option value={value.id} key={value.id}>{value.name}</option>;
			})}
		</select>
	);
}

export default DropDown;
