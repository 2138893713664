
function PickFile({audioFile, changeAudioFile}) {
	return (
		<div className="mb-3">
			<label htmlFor="file" className="form-label">Audio file: </label>
			<input type={"file"} className={"form-control"} id={"file"} ref={audioFile} onChange={changeAudioFile}/>
		</div>
	);
}

export default PickFile;
