import useZoom from "./useZoom";
import {useContext} from "react";
import {waveSurferContext} from "../../../../contexts/waveSurfer";

function Zoom() {
	const {startZoomingOut, startZoomingIn, end} = useZoom();
	const {zoom} = useContext(waveSurferContext);

	return (
		<>
			<button type={"button"} onMouseDown={startZoomingOut} onMouseUp={end} disabled={zoom === 0} className={"btn btn-secondary"}>Zoom out</button>
			<button type={"button"} onMouseDown={startZoomingIn} onMouseUp={end} className={"btn btn-secondary"}>Zoom in</button>
		</>
	);
}

export default Zoom;
