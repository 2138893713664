
function PickFile({assFile, changeAssFile}) {
	return (
		<div className="mb-3">
			<label htmlFor="assFile" className="form-label">ASS file: </label>
			<input type={"file"} className={"form-control"} id={"assFile"} ref={assFile} onChange={changeAssFile}/>
		</div>
	);
}

export default PickFile;
