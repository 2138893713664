import useRemoveBackDrops from "../../../hooks/useRemoveBackDrops";
import {useContext} from "react";
import {waveSurferContext} from "../../../contexts/waveSurfer";

function DeleteRegion({region}) {
	useRemoveBackDrops();
	const {waveSurfer} = useContext(waveSurferContext);
	const regionInstance = waveSurfer.current.regions.list[region.id];

	function onSubmit(evt) {
		evt.preventDefault();
		regionInstance.remove();
	}

	return (
		<>
			<button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target={"#deleteRegion" + region.id}>Delete</button>
			<div className="modal fade" id={"deleteRegion" + region.id} tabIndex="-1" aria-labelledby={"deleteRegion" +region.id + "Label"} aria-hidden="true">
				<div className="modal-dialog">
					<form onSubmit={onSubmit}>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id={"deleteRegion" +region.id + "Label"}>Delete segment</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">
								<p>Are you sure you want to delete this segment?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
								<button type="submit" className="btn btn-danger">Yes</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default DeleteRegion;
