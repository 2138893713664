import Audio from "./components/Audio";
import Navbar from "./components/Navbar";
import Container from "./components/Generic/Container";
import Regions from "./components/Regions";
import {ShortCutsProvider} from "./contexts/shortCuts";
import {useContext} from "react";
import {waveSurferContext} from "./contexts/waveSurfer";
import {LabelsProvider} from "./contexts/labels";

function App() {
	const {isReady} = useContext(waveSurferContext);

	return (
		<div>
			<ShortCutsProvider>
				<LabelsProvider>
					<div>
						<Container>
							<Navbar />
						</Container>
						<Container>
							<Audio />
						</Container>
					</div>
					{isReady && <div className={"overflow-auto"} style={{maxHeight: '50vh'}}>
						<Container>
							<Regions />
						</Container>
					</div>}
				</LabelsProvider>
			</ShortCutsProvider>
		</div>
	);
}



export default App;
