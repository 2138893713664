import WaveForm from "./WaveForm";
import Controls from "./Controls";
import {useContext, useEffect, useRef, useState} from "react";
import {waveSurferContext} from "../../contexts/waveSurfer";
import PickFile from "./PickFile";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/src/plugin/regions";
import CursorPlugin from "wavesurfer.js/src/plugin/cursor";
import Spinner from "../Generic/Spinner";
import getScrollbarVisible from "../../util/getScrollbarVisible";

function Audio() {
	const {waveSurfer, waveFormDiv, intervalId, setZoom, isReady, setIsReady} = useContext(waveSurferContext);
	const audioFile = useRef();
	const [loading, setLoading] = useState(false);
	const abortController = useRef(null);

	// clear intervalId for playback rate change
	useEffect(() => {
		if (isReady) {
			scrollInitialise();

			window.addEventListener("resize", (evt) => {
				setZoom(0);
				scrollInitialise();
			});

			return () => {
				clearInterval(intervalId.current);
				window.removeEventListener("resize", scrollInitialise);
				abortController.current && abortController.current.abort();
			}
		}
		function scrollInitialise() {
			//set initial minPxPerSec because of the fillParent option
			abortController.current && abortController.current.abort();
			abortController.current = new AbortController();

			waveSurfer.current.zoom(waveSurfer.current.params.minPxPerSec + 1);

			let intervalId;

			const intervalId2 = setInterval(() => {
				// let scrollbar appear
				if (!getScrollbarVisible(waveFormDiv.current.firstElementChild)) {
					waveSurfer.current.zoom(waveSurfer.current.params.minPxPerSec + 1);
				}
				else {
					clearInterval(intervalId2);
					// let scrollbar disappear
					intervalId = setInterval(() => {
						if (getScrollbarVisible(waveFormDiv.current.firstElementChild)) {
							waveSurfer.current.zoom(waveSurfer.current.params.minPxPerSec - 1);
						}
						else {
							clearInterval(intervalId);
						}
					});
				}
			});
			// interval is used, so it can be non-blocking and I can cancel it using abortController

			abortController.current.signal.addEventListener('abort', (evt) => {
				intervalId && clearInterval(intervalId);
				intervalId2 && clearInterval(intervalId2);
			});
		}
	}, [isReady]);



	async function changeAudioFile() {
		if (audioFile.current.files.length === 1) {
			setIsReady(false);
			setLoading(true);
			console.log(waveSurfer.current)
			waveSurfer.current && waveSurfer.current.destroy();
			waveSurfer.current = WaveSurfer.create({
				container: waveFormDiv.current,
				waveColor: 'violet',
				progressColor: 'purple',
				forceDecode: true,
				plugins: [
					RegionsPlugin.create({
						dragSelection: true
					}),
					CursorPlugin.create({
						showTime: true,
						opacity: 1,
						customShowTimeStyle: {
							'background-color': '#000',
							color: '#fff',
							padding: '2px',
							'font-size': '10px'
						}
					})
				],
				barHeight: 1.5,
				height: 250,
				loopSelection: false,
				fillParent: true,
				autoCenter: false,
				responsive: true
			});

			waveSurfer.current.loadBlob(audioFile.current.files[0]);
			waveSurfer.current.on('ready', (evt) => {
				setIsReady(true);
				setLoading(false);
			});
		}
	}

	return (
		<>
			<PickFile audioFile={audioFile} changeAudioFile={changeAudioFile}/>
			{isReady && <hr />}
			{loading && <Spinner />}
			<WaveForm />
			{isReady && <hr />}
			{isReady && <Controls />}
		</>
	);
}

export default Audio;
