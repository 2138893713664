import {useState} from "react";

function ShortCutRow({shortCut, setShortCuts, index}) {
	const [isEditing, setIsEditing] = useState(false);

	function onClick() {
		setIsEditing(true);
	}

	function onKeyDown(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		if (!["Control", "Shift", "Alt", "AltGraph", "Meta"].includes(evt.key)) {
			setShortCuts(prevState => {
				const newState = [...prevState];
				if (!newState.find(value => {
					if (value.ctrl !== evt.ctrlKey) {
						return false;
					}
					if (value.shift !== evt.shiftKey) {
						return false;
					}
					if (value.alt !== evt.altKey) {
						return false;
					}
					if (value.meta !== evt.metaKey) {
						return false;
					}
					return value.key === evt.code;
				})) {
					newState[index] = {
						...newState[index],
						key: evt.code,
						shift: evt.shiftKey,
						ctrl: evt.ctrlKey,
						alt: evt.altKey,
						meta: evt.metaKey
					};
				}

				return newState;
			});
			setIsEditing(false);
		}
	}

	let shortCutString = "";

	if (shortCut.ctrl) {
		shortCutString += "Ctrl + ";
	}
	if (shortCut.shift) {
		shortCutString += "Shift + ";
	}
	if (shortCut.alt) {
		shortCutString += "Alt + ";
	}
	if (shortCut.meta) {
		shortCutString += "Meta + ";
	}
	shortCutString += shortCut.key;

	return (
		<tr>
			<td onClick={onClick}>{isEditing ? <input className={"form-control"} type={"text"} autoFocus={true} onBlur={() => {
				setIsEditing(false);
			}} readOnly={true} onKeyDown={onKeyDown} value={"Enter your shortcut here"} />: shortCutString}</td>
			<td>{shortCut.name}</td>
		</tr>
	);
}

export default ShortCutRow;
