import RegionsTable from "./RegionsTable";
import {RegionsProvider} from "../../contexts/regions";
import ImportRegions from "./ImportRegions";

function Regions() {
	return (
		<RegionsProvider>
			<ImportRegions />
			<hr />
			<RegionsTable />
		</RegionsProvider>
	);
}

export default Regions;
