import {useContext} from "react";
import {labelsContext} from "../../contexts/labels";

function RegionsTableHead() {
	const {dropDowns, booleans} = useContext(labelsContext);

	return (
		<thead className={"table-secondary"}>
			<tr>
				<th>Start</th>
				<th>End</th>
				{dropDowns.map(value => {
					return <th key={value.id}>{value.name}</th>
				})}
				{booleans.map(value => {
					return <th key={value.id}>{value.name}</th>
				})}
				<th style={{width: '100%'}}>Text</th>
				<th>Actions</th>
			</tr>
		</thead>
	);
}

export default RegionsTableHead;
