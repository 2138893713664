import {useContext} from "react";
import {waveSurferContext} from "../../../../contexts/waveSurfer";

export default function useZoom() {
	const {waveSurfer, setZoom, intervalId} = useContext(waveSurferContext);

	function zoomIn() {
		waveSurfer.current.zoom(waveSurfer.current.params.minPxPerSec + 1);
		setZoom(prevState => prevState + 1);
	}
	function zoomOut() {
		waveSurfer.current.zoom(waveSurfer.current.params.minPxPerSec - 1);
		setZoom(prevState => prevState - 1);
	}

	function startZoomingOut() {
		clearInterval(intervalId.current);
		intervalId.current = setInterval(zoomOut, 50);
	}
	function startZoomingIn() {
		clearInterval(intervalId.current);
		intervalId.current = setInterval(zoomIn, 50);
	}
	function end() {
		clearInterval(intervalId.current);
	}

	return {
		startZoomingIn,
		startZoomingOut,
		end
	};
}
