import {useContext, useRef, useState} from "react";
import PickFile from "./PickFile";
import Spinner from "../../Generic/Spinner";
import {stringToSeconds} from "../../../util/converters";
import Error from "../../Generic/Error";
import {regionsContext} from "../../../contexts/regions";

function ImportRegions() {
	const assFile = useRef();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {addRegion} = useContext(regionsContext);

	async function changeAssFile() {
		if (assFile.current.files.length === 1) {
			setLoading(true);
			setError(null);

			try {
				const text = await assFile.current.files[0].text();
				let rows = text.split(/\r\n|\r|\n/);
				rows = rows.slice(rows.findIndex(value => value === "[Events]") + 2).filter(value => value).map(value => {
					const row = value.split(',');
					return {
						start: stringToSeconds(row[1]),
						end: stringToSeconds(row[2]),
						text: row[9]
					};
				});
				for (const row of rows) {
					addRegion(row);
				}
			}
			catch (e) {
				console.error(e);
				setError("Failed to import this file.");
			}
			setLoading(false);
		}
	}

	return (
		<div>
			{error && <Error message={error} />}
			<PickFile assFile={assFile} changeAssFile={changeAssFile} />
			{loading && <Spinner />}
		</div>
	);
}

export default ImportRegions;
