import React, {useState} from "react";

function AddBoolean({addBoolean}) {
	const [name, setName] = useState('');

	function onSubmit(evt) {
		evt.preventDefault();
		try {
			addBoolean(name);
			setName('');
		}
		catch (e) {
			console.error(e);
		}
	}
	return (
		<form onSubmit={onSubmit} className="d-flex justify-content-between">
			<div>
				<input required={true} type="text" className={"form-control"} placeholder={"Name"} value={name} onKeyDown={evt => {
					evt.stopPropagation();
				}} onChange={(evt) => {
					setName(evt.target.value);
				}}/>
			</div>
			<div>
				<button className={"btn btn-primary btn-sm"} type={"submit"}>Add boolean</button>
			</div>
		</form>
	);
}

export default AddBoolean;
