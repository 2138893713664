import {secondsToString} from "../../util/converters";
import {useContext, useEffect, useState} from "react";
import DeleteRegion from "./Actions/DeleteRegion";
import PlayRegion from "./Actions/PlayRegion";
import EditText from "./Actions/EditText";
import {waveSurferContext} from "../../contexts/waveSurfer";
import {labelsContext} from "../../contexts/labels";
import DropDown from "./Labels/DropDown";
import Boolean from "./Labels/Boolean";

function RegionRow({region}) {
	const [isActive, setIsActive] = useState(false);
	const {waveSurfer} = useContext(waveSurferContext);
	const {dropDowns, booleans} = useContext(labelsContext);

	useEffect(() => {
		const regionInstance = waveSurfer.current.regions.list[region.id];

		regionInstance.on('in', activate);
		regionInstance.on('out', deactivate);

		return () => {
			regionInstance.un('in', activate);
			regionInstance.un('out', deactivate);
		};

		function activate() {
			setIsActive(true);
		}
		function deactivate() {
			setIsActive(false);
		}
	}, []);

	let className = "";
	if (isActive) {
		className = "table-success";
	}

	return (
		<tr className={className}>
			<td>{secondsToString(region.start)}</td>
			<td>{secondsToString(region.end)}</td>
			{dropDowns.map(value => {
				return <td key={value.id}><DropDown dropDown={value}  region={region} /></td>;
			})}
			{booleans.map(value => {
				return <td key={value.id}><Boolean boolean={value}  region={region} /></td>;
			})}
			<td><EditText region={region}/></td>
			<td>
				<div className={"d-flex align-items-center gap-2"}>
					<PlayRegion region={region}/>
					<DeleteRegion region={region} />
				</div>

			</td>
		</tr>
	);
}

export default RegionRow;
