import React, {useContext} from "react";
import {v4} from "uuid";
import DropDownRow from "./DropDownRow";
import AddDropDown from "./AddDropDown";
import {labelsContext} from "../../../../../contexts/labels";

function DropDowns({inputNames}) {
	const {dropDowns, setDropDowns} = useContext(labelsContext);

	function addDropDown(name, multiple) {
		if (!inputNames.includes(name.trim())) {
			setDropDowns((prevState) => {
				return [...prevState, {
					id: v4(),
					name,
					options: [],
					multiple
				}];
			});
		}
		else {
			throw new Error("This input name is already in use.");
		}
	}

	return (
		<>
			<AddDropDown addDropDown={addDropDown}/>
			<table className={"table table-sm table-light table-striped mt-2"}>
				<caption>Dropdowns</caption>
				<thead className={"table-dark"}>
				<tr>
					<th>Name</th>
					<th>Multiple</th>
					<th>Options</th>
					<th>Actions</th>
				</tr>
				</thead>
				{dropDowns.map(dropDown => {
					return (
						<DropDownRow key={dropDown.id} dropDown={dropDown} />
					);
				})}
			</table>
		</>
	);
}

export default DropDowns;
