import ShortCutsTableHead from "./ShortCutsTableHead";
import ShortCutRow from "./ShortCutRow";

function ShortCutsTable({shortCuts, setShortCuts}) {
	return (
		<>
			<table className={"table table-light table-bordered table-hover"}>
				<ShortCutsTableHead />
				<tbody>
				{shortCuts.map((value, index) => {
					return <ShortCutRow shortCut={value} setShortCuts={setShortCuts} index={index} key={index}/>
				})}
				</tbody>
			</table>
		</>
	);
}

export default ShortCutsTable;
