import {useContext, useEffect, useState} from "react";
import {waveSurferContext} from "../../../contexts/waveSurfer";

function PlayRegion({region}) {
	const [loop, setLoop] = useState(false);
	const {waveSurfer} = useContext(waveSurferContext);

	const regionInstance = waveSurfer.current.regions.list[region.id];

	function play() {
		regionInstance && regionInstance.play();
	}

	useEffect(() => {
		regionInstance && (regionInstance.loop = loop);
	}, [loop]);

	return (
		<>
			<div className="form-check form-switch">
				<input className="form-check-input" type="checkbox" role="switch" id={"loop" + region.id} checked={loop} onChange={evt => {
					setLoop(evt.target.checked);
				}}/>
				<label className="form-check-label" htmlFor={"loop" + region.id}>Loop</label>
			</div>
			<button onClick={play} className={"btn btn-sm btn-success"}>Play</button>
		</>
	);
}

export default PlayRegion;
