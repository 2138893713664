import {useContext} from "react";
import {waveSurferContext} from "../../../../contexts/waveSurfer";

function usePlaybackRate() {
	const {playbackRate, setPlaybackRate, intervalId} = useContext(waveSurferContext);

	function speedUp() {
		setPlaybackRate(prevState => prevState + 0.01);
	}
	function slowDown() {
		setPlaybackRate(prevState => prevState - 0.01);
	}

	function startSlowingDown() {
		clearInterval(intervalId.current);
		intervalId.current = setInterval(slowDown, 50);
	}
	function startSpeedingUp() {
		clearInterval(intervalId.current);
		intervalId.current = setInterval(speedUp, 50);
	}
	function end() {
		clearInterval(intervalId.current);
	}
	function resetPlaybackRate() {
		setPlaybackRate(1);
	}

	return {
		playbackRate,
		startSpeedingUp,
		startSlowingDown,
		speedUp,
		slowDown,
		resetPlaybackRate,
		end
	};
}

export default usePlaybackRate;
