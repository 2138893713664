import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {waveSurferContext} from "../waveSurfer";
import {v4} from "uuid";

export const regionsContext = createContext({
	regions: [],
	setRegions: [],
	updateRegion: (region) => {}
});

export function RegionsProvider({children}) {
	const [regions, setRegions] = useState([]);

	const {waveSurfer, isReady} = useContext(waveSurferContext);

	useEffect(() => {
		waveSurfer.current && waveSurfer.current.on('region-created', addRegion);
		waveSurfer.current && waveSurfer.current.on('region-updated', updateRegion);
		waveSurfer.current && waveSurfer.current.on('region-removed', removeRegion);

		return () => {
			waveSurfer.current && waveSurfer.current.un('region-created', addRegion);
			waveSurfer.current && waveSurfer.current.un('region-updated', updateRegion);
			waveSurfer.current && waveSurfer.current.on('region-removed', removeRegion);
		}
		function removeRegion(region) {
			setRegions(prevState => {
				return [...prevState].filter(value => value.id !== region.id);
			});
		}
		function addRegion(region) {
			setRegions(prevState => {
				const foundRegion = prevState.find(value => value.id === region.id);

				if (!foundRegion) { // don't add region to state if it's already there
					return [...prevState, {id: region.id, start: region.start, end: region.end, text: '', isReady: true}].sort((a, b) => {
						return a.start - b.start;
					});
				}
				else if (!foundRegion.isActive){
					const newState = [...prevState];
					newState[newState.findIndex(value => value.id === region.id)].isReady = true;
					return newState;
				}
				else {
					return prevState;
				}
			});
		}
		function updateRegion(region) {
			setRegions(prevState => {
				const newState = [...prevState];
				newState[newState.findIndex(value => value.id === region.id)] = {
					...newState[newState.findIndex(value => value.id === region.id)],
					start: region.start,
					end: region.end
				};
				return newState.sort((a, b) => {
					return a.start - b.start;
				});
			});
		}
	}, [isReady]);

	useEffect(() => {
		//check if region exists in the waveform
		for (let i = 0; i < regions.length; i++) {
			if (!regions[i].isReady) {
				waveSurfer.current.addRegion(regions[i]);
			}
		}
	}, [regions.length]); // because I only want to run this if a region is created, if it's created by code I have to add it to the waveform.

	// console.log(regions.length);

	const addRegion = useCallback(region => {
		const regionAlreadyExists = regions.find(value => value.id === region.id);
		if (!regionAlreadyExists) {
			if (region.end < waveSurfer.current.getDuration()) {

				setRegions(prevState => {
					return [...prevState, {id: region.id || v4(), start: region.start, end: region.end, text: region.text || '', isReady: false}].sort((a, b) => {
						return a.start - b.start;
					});
				});
			}
		}
		else {
			console.log("region already exists");
		}
	}, [regions.length])

	const updateRegion = useCallback(region => {
		setRegions(prevState => {
			const newState = [...prevState];
			newState[newState.findIndex(value => value.id === region.id)] = {
				...newState[newState.findIndex(value => value.id === region.id)],
				...region
			};
			return newState;
		});
	}, []);

	return (
		<regionsContext.Provider value={{regions, setRegions, updateRegion, addRegion}}>
			{children}
		</regionsContext.Provider>
	);
}
