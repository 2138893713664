import {useContext} from "react";
import {waveSurferContext} from "../../contexts/waveSurfer";

function WaveForm() {
	const {waveFormDiv} = useContext(waveSurferContext);

	return (
		<div ref={waveFormDiv}/>
	);
}

export default WaveForm;
