import React, {useContext} from "react";
import {labelsContext} from "../../../../../contexts/labels";

function BooleanRow({boolean}) {
	const {setBooleans} = useContext(labelsContext);

	return (
		<tr>
			<td>{boolean.name}</td>
			<td><button className={"btn btn-danger btn-sm"} type={"button"} onClick={(evt) => {
				setBooleans((prevState) => {
					return prevState.filter(current => current.id !== boolean.id);
				})
			}}>Remove</button></td>
		</tr>

	);
}

export default BooleanRow;
