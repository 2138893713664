export function stringToSeconds(string) {
	const split = string.split(':');
	const hours = split[0];
	const minutes = split[1];
	const seconds = split[2];

	return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseFloat(seconds);
}

export function secondsToString(seconds) {
	let hours = Math.floor(seconds / 3600);
	hours < 10 && (hours = "0" + hours);
	let minutes = Math.floor(seconds / 60);
	minutes < 10 && (minutes = "0" + minutes);
	seconds %= 60;
	seconds = Math.floor(seconds * 100) / 100;
	seconds = seconds.toFixed(2);
	seconds < 10 && (seconds = "0" + seconds);
	return hours + ":" + minutes + ":" + seconds;
}
