import {useContext, useEffect, useState} from "react";
import {waveSurferContext} from "../../../contexts/waveSurfer";
import Zoom from "./Zoom";
import PlaybackRate from "./PlaybackRate/PlaybackRate";
import useShortCuts from "./useShortCuts";

function Controls() {
	const {waveSurfer} = useContext(waveSurferContext);
	const [isPlaying, setIsPlaying] = useState(false);
	useShortCuts();

	useEffect(() => {
		setIsPlaying(waveSurfer.current.isPlaying());
		waveSurfer.current.on('play', play);
		waveSurfer.current.on('pause', pause);
		return () => {
			waveSurfer.current.un('play', play);
			waveSurfer.current.un('pause', pause);
		}
	}, []);

	function play() {
		setIsPlaying(true);
	}
	function pause() {
		setIsPlaying(false);
	}

	function playPause() {
		if (isPlaying) {
			waveSurfer.current.pause();
		}
		else {
			waveSurfer.current.play();
		}
	}

	return (
		<div>
			<div className={"d-flex justify-content-center gap-1"}>
				<button type={"button"} onClick={playPause} className={"btn btn-primary"}>{isPlaying ? 'Pause':'Play'}</button>
				<Zoom />
				<PlaybackRate />
			</div>
		</div>
	);
}

export default Controls;
