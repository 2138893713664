
function ShortCutsTableHead() {
	return (
		<thead className={"table-dark"}>
			<tr>
				<th>Shortcut (click to edit)</th>
				<th>Description</th>
			</tr>
		</thead>
	);
}

export default ShortCutsTableHead;
