import DropDowns from "./InputTypes/DropDowns";
import Booleans from "./InputTypes/Booleans";
import {useContext, useEffect, useState} from "react";
import {labelsContext} from "../../../contexts/labels";

function Labels() {
	const {dropDowns, booleans} = useContext(labelsContext);

	const [inputNames, setInputNames] = useState([]);

	useEffect(() => {
		setInputNames([...dropDowns.map(value => value.name), ...booleans.map(value => value.name)]);
	}, [dropDowns, booleans]);


	return (
		<>
			<ul className="nav nav-tabs" id="labelsTab" role="tablist">
				<li className="nav-item" role="presentation">
					<button className="nav-link active" id="dropDowns-tab" data-bs-toggle="tab"
							data-bs-target="#dropDowns-tab-pane" type="button" role="tab" aria-controls="dropDowns-tab-pane"
							aria-selected="true">Dropdowns
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button className="nav-link" id="booleans-tab" data-bs-toggle="tab"
							data-bs-target="#booleans-tab-pane" type="button" role="tab" aria-controls="booleans-tab-pane"
							aria-selected="false">Booleans
					</button>
				</li>
				{/*<li className="nav-item" role="presentation">
					<button className="nav-link" id="textFields-tab" data-bs-toggle="tab"
							data-bs-target="#textFields-tab-pane" type="button" role="tab" aria-controls="textFields-tab-pane"
							aria-selected="false">Text fields
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button className="nav-link" id="lists-tab" data-bs-toggle="tab"
							data-bs-target="#lists-tab-pane" type="button" role="tab" aria-controls="lists-tab-pane"
							aria-selected="false">Lists
					</button>
				</li>*/}
			</ul>
			<div className="tab-content mt-3" id="labelsContent">
				<div className="tab-pane fade show active" id="dropDowns-tab-pane" role="tabpanel" aria-labelledby="dropDowns-tab"
					 tabIndex="0">
					<DropDowns inputNames={inputNames} />
				</div>
				<div className="tab-pane fade" id="booleans-tab-pane" role="tabpanel" aria-labelledby="booleans-tab"
					 tabIndex="0">
					<Booleans inputNames={inputNames}/>
				</div>
				{/*<div className="tab-pane fade" id="textFields-tab-pane" role="tabpanel" aria-labelledby="textFields-tab"
					 tabIndex="0">
					<TextFields textFields={textFields} setTextFields={setTextFields} inputNames={inputNames}/>
				</div>
				<div className="tab-pane fade" id="lists-tab-pane" role="tabpanel" aria-labelledby="lists-tab"
					 tabIndex="0">
					<Lists lists={lists} setLists={setLists} inputNames={inputNames}/>
				</div>*/}
			</div>
		</>
	);
}

export default Labels;
