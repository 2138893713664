import {useContext, useState} from "react";
import {shortCutsContext} from "../../../contexts/shortCuts";
import ShortCutsTable from "./ShortCutsTable";

function ShortCuts() {
	const {audioPlayer, setAudioPlayer} = useContext(shortCutsContext);
	// const [generalShortCuts, setGeneralShortCuts] = useState(audioPlayer);

	return (
		<>
			<ul className="nav nav-tabs" id="shortCutsTab" role="tablist">
				<li className="nav-item" role="presentation">
					<button className="nav-link active" id="audioPlayer-tab" data-bs-toggle="tab"
							data-bs-target="#audioPlayer-tab-pane" type="button" role="tab" aria-controls="audioPlayer-tab-pane"
							aria-selected="true">Audio Player
					</button>
				</li>
				{/*<li className="nav-item" role="presentation">
					<button className="nav-link" id="general-tab" data-bs-toggle="tab"
							data-bs-target="#general-tab-pane" type="button" role="tab" aria-controls="general-tab-pane"
							aria-selected="false">General
					</button>
				</li>*/}
			</ul>
			<div className="tab-content mt-3" id="shortCutsContent">
				<div className="tab-pane fade show active" id="audioPlayer-tab-pane" role="tabpanel" aria-labelledby="audioPlayer-tab"
					 tabIndex="0">
					<ShortCutsTable shortCuts={audioPlayer} setShortCuts={setAudioPlayer}/>
				</div>
				{/*<div className="tab-pane fade" id="general-tab-pane" role="tabpanel" aria-labelledby="general-tab"
					 tabIndex="0">
					<ShortCutsTable shortCuts={generalShortCuts} setShortCuts={setGeneralShortCuts}/>
				</div>*/}
			</div>
		</>
	);
}

export default ShortCuts;
