import ShortCuts from "./ShortCuts";
import Labels from "./Labels";

function Tabs() {
	return(
		<>
			<ul className="nav nav-tabs" id="settingsTab" role="tablist">
				<li className="nav-item" role="presentation">
					<button className="nav-link active" id="shortcuts-tab" data-bs-toggle="tab"
							data-bs-target="#shortcuts-tab-pane" type="button" role="tab" aria-controls="shortcuts-tab-pane"
							aria-selected="true">Shortcuts
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button className="nav-link" id="labels-tab" data-bs-toggle="tab"
							data-bs-target="#labels-tab-pane" type="button" role="tab" aria-controls="labels-tab-pane"
							aria-selected="false">Labels
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button className="nav-link" id="tags-tab" data-bs-toggle="tab"
							data-bs-target="#tags-tab-pane" type="button" role="tab" aria-controls="tags-tab-pane"
							aria-selected="false">Tags
					</button>
				</li>
			</ul>
			<div className="tab-content" id="settingsContent">
				<div className="tab-pane fade show active" id="shortcuts-tab-pane" role="tabpanel" aria-labelledby="shortcuts-tab"
					 tabIndex="0">
					<ShortCuts />
				</div>
				<div className="tab-pane fade" id="labels-tab-pane" role="tabpanel" aria-labelledby="labels-tab"
					 tabIndex="0">
					<Labels />
				</div>
				<div className="tab-pane fade" id="tags-tab-pane" role="tabpanel" aria-labelledby="tags-tab"
					 tabIndex="0">

				</div>
			</div>
		</>
	);
}

export default Tabs;
