import React, {useContext} from "react";
import {v4} from "uuid";
import AddOption from "./AddOption";
import {labelsContext} from "../../../../../contexts/labels";

function DropDownRow({dropDown}) {
	const {setDropDowns} = useContext(labelsContext);

	function addOption(option) {
		if (option) {
			if (!dropDown.options.map(value => value.name).includes(option)) {
				setDropDowns((prevState) => {
					const newState = [...prevState];
					newState[newState.findIndex(value => value.id === dropDown.id)].options.push({
						id: v4(),
						name: option
					});
					return newState
				});
			}
			else {
				throw new Error("This option already exists.");
			}
		}
	}
	function removeOption(optionId) {
		setDropDowns((prevState) => {
			const newState = [...prevState];
			newState[newState.findIndex(value => value.id === dropDown.id)].options =
				newState[newState.findIndex(value => value.id === dropDown.id)].options.filter(value => value.id !== optionId);
			return newState;
		});
	}

	return (
		<tbody>
			<tr>
				<td rowSpan={dropDown.options.length + 1}>{dropDown.name}</td>
				<td rowSpan={dropDown.options.length + 1}><input type={"checkbox"} readOnly={true} checked={dropDown.multiple} /></td>
				<td><AddOption addOption={addOption}/></td>
				<td><button className={"btn btn-danger btn-sm"} type={"button"} onClick={(evt) => {
					setDropDowns((prevState) => {
						return prevState.filter(current => current.id !== dropDown.id);
					})
				}}>Remove</button></td>
			</tr>
			{dropDown.options.map(option => {
				return (
					<tr key={option.id}>
						<td>{option.name}</td>
						<td><button className={"btn btn-danger btn-sm"} type={"button"} onClick={(evt) => {
							removeOption(option.id);
						}}>Remove</button></td>
					</tr>
				);
			})}
		</tbody>
	);
}

export default DropDownRow;
