import {useContext, useEffect} from "react";
import {waveSurferContext} from "../../../contexts/waveSurfer";
import usePlaybackRate from "./PlaybackRate/usePlaybackRate";
import useZoom from "./Zoom/useZoom";
import {shortCutsContext} from "../../../contexts/shortCuts";

function useShortCuts() {
	const {waveSurfer, zoom} = useContext(waveSurferContext);
	const {speedUp, slowDown, resetPlaybackRate} = usePlaybackRate();
	const {startZoomingOut, startZoomingIn, end} = useZoom();
	const {audioPlayer} = useContext(shortCutsContext);

	const audioPlayerText = JSON.stringify(audioPlayer);

	useEffect(() => {
		window.addEventListener("keyup", onKeyUp);
		return () => {
			window.removeEventListener("keyup", onKeyUp);
		}
		function onKeyUp(evt) {
			end(); // stop zooming
		}
	}, []); // because keyup doesn't care about the zoom value

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		}
		function onKeyDown(evt) {
			const foundShortCut = audioPlayer.find(value => {
				if (value.ctrl !== evt.ctrlKey) {
					return false;
				}
				if (value.shift !== evt.shiftKey) {
					return false;
				}
				if (value.alt !== evt.altKey) {
					return false;
				}
				if (value.meta !== evt.metaKey) {
					return false;
				}
				return value.key === evt.code;
			});

			if (foundShortCut) {
				switch (foundShortCut.name) {
					case "Play/Pause":
						evt.preventDefault();
						waveSurfer.current.isPlaying() ? waveSurfer.current.pause() : waveSurfer.current.play(); // pause & play
						break;
					case "Fast forward":
						evt.preventDefault();
						waveSurfer.current.skipForward(1);
						break;
					case "Rewind":
						evt.preventDefault();
						waveSurfer.current.skipBackward(1);
						break;
					case "Zoom in":
						evt.preventDefault();
						if (!evt.repeat) {
							startZoomingIn();
						}
						break;
					case "Zoom out":
						evt.preventDefault();
						if (!evt.repeat && zoom !== 0) {
							startZoomingOut();
						}
						break;
					case "Speed up":
						evt.preventDefault();
						speedUp();
						break;
					case "Slow down":
						evt.preventDefault();
						slowDown();
						break;
					case "Reset playback speed":
						evt.preventDefault();
						resetPlaybackRate();
						break;
					default:
						break;
				}
			}
		}
	}, [zoom, audioPlayerText]);
}

export default useShortCuts;
