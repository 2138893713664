import React, {useState} from "react";

function AddOption({addOption}) {
	const [option, setOption] = useState('');

	return (
		<input type="text" className={"form-control"} placeholder={"Option"} value={option} onChange={(evt) => {
			setOption(evt.target.value);
		}} onKeyDown={(evt) => {
			evt.stopPropagation();
			if (evt.key === "Enter") {
				evt.preventDefault();
				try {
					addOption(option);
					setOption('');
				}
				catch (e) {
					console.error(e);
				}
			}
		}}/>
	);
}

export default AddOption;
