import Tabs from "./Tabs";

function Settings() {
	return (
		<>
			<button className={"btn btn-secondary"} data-bs-toggle={"modal"} data-bs-target={"#settingsModal"}>Settings</button>
			<div className="modal fade" id={"settingsModal"} tabIndex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="settingsModalLabel">Settings</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<Tabs />
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Settings;
