import {createContext, useEffect, useState} from "react";

export const labelsContext = createContext({
	dropDowns: [],
	setDropDowns: (dropDowns) => {}
});

let initialDropDowns = [];
let initialBooleans = [];

if (localStorage.getItem('dropDowns')) {
	try {
		initialDropDowns = JSON.parse(localStorage.getItem('dropDowns'));
	}
	catch (e) {
		console.error(e);
	}
}
if (localStorage.getItem('booleans')) {
	try {
		initialBooleans = JSON.parse(localStorage.getItem('booleans'));
	}
	catch (e) {
		console.error(e);
	}
}

export function LabelsProvider({children}) {
	const [dropDowns, setDropDowns] = useState(initialDropDowns);
	const [booleans, setBooleans] = useState(initialBooleans);

	useEffect(() => {
		localStorage.setItem('dropDowns', JSON.stringify(dropDowns));
	}, [dropDowns]);
	useEffect(() => {
		localStorage.setItem('booleans', JSON.stringify(booleans));
	}, [booleans]);

	return (
		<labelsContext.Provider value={{
			dropDowns,
			setDropDowns,
			booleans, setBooleans
		}}>
		{children}
	</labelsContext.Provider>);
}
