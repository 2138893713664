import React, {useContext} from "react";
import {v4} from "uuid";
import BooleanRow from "./BooleanRow";
import {labelsContext} from "../../../../../contexts/labels";
import AddBoolean from "./AddBoolean";

function Booleans({inputNames}) {
	const {setBooleans, booleans} = useContext(labelsContext);

	function addBoolean(name) {
		if (!inputNames.includes(name.trim())) {
			setBooleans((prevState) => {
				return [...prevState, {
					id: v4(),
					name
				}];
			});
		}
		else {
			throw new Error("This name already exists.");
		}
	}

	return (
		<>
			<AddBoolean addBoolean={addBoolean} />
			<table className={"table table-sm table-light table-striped mt-2"}>
				<caption>Booleans</caption>
				<thead className={"table-dark"}>
				<tr>
					<th>Name</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{booleans.map(boolean => {
					return (
						<BooleanRow key={boolean.id} boolean={boolean}/>
					);
				})}
				</tbody>

			</table>
		</>
	);
}

export default Booleans;
