import Settings from "../Settings";

function Navbar() {
	return (
		<div className={"d-flex align-items-center justify-content-between"}>
			<div>

			</div>
			<div>
				<h2>Audio transcription tool</h2>
			</div>
			<div>
				<Settings />
			</div>
		</div>
	)
}

export default Navbar;
