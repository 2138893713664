
function Container({children}) {
	return (
		<div className={"border shadow-sm p-3 m-3 bg-white"}>
			{children}
		</div>
	);
}

export default Container;
