import {useContext} from "react";
import {regionsContext} from "../../../contexts/regions";

function EditText({region}) {
	const {updateRegion, addRegion} = useContext(regionsContext);

	function onKeyDown(evt) {
		evt.stopPropagation(); //stop keyboard shortcuts for audio player

		switch (evt.key) {
			case "Enter": // create new Region
				addRegion({
					start: region.end,
					end: region.end + 10
				});
				break;
			default:
				break;
		}
	}

	return (
		<input className={"form-control"} autoFocus={true} value={region.text} onKeyDown={onKeyDown} onChange={(evt) => {
			updateRegion({
				id: region.id,
				text: evt.target.value
			});
		}} placeholder={"Enter your translation here..."} />
	);
}

export default EditText;
