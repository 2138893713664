import {createContext, useEffect, useState} from "react";

const defaultOptions = [{
	name: "Play/Pause",
	key: "Space",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Zoom out",
	key: "NumpadSubtract",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Zoom in",
	key: "NumpadAdd",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Fast forward",
	key: "ArrowRight",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Rewind",
	key: "ArrowLeft",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Speed up",
	key: "ArrowUp",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Slow down",
	key: "ArrowDown",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}, {
	name: "Reset playback speed",
	key: "Enter",
	shift: false,
	ctrl: false,
	alt: false,
	meta: false
}];

export const shortCutsContext = createContext({
	audioPlayer: defaultOptions
});

let initialState = [];
if (localStorage.getItem('audioPlayerShortCuts')) {
	try {
		initialState = JSON.parse(localStorage.getItem('audioPlayerShortCuts'));
	}
	catch (e) {
		console.error(e);
		initialState = defaultOptions;
	}
}
else {
	initialState = defaultOptions;
}

export function ShortCutsProvider({children}) {
	const [audioPlayer, setAudioPlayer] = useState(initialState);

	const audioPlayerText = JSON.stringify(audioPlayer);

	useEffect(() => {
		localStorage.setItem('audioPlayerShortCuts', audioPlayerText);
	}, [audioPlayerText]);

	return (
		<shortCutsContext.Provider value={{
			audioPlayer,
			setAudioPlayer
			}}>
		{children}
	</shortCutsContext.Provider>);
}
