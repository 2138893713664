import {createContext, useEffect, useRef, useState} from "react";

export const waveSurferContext = createContext({
	waveSurfer: null,
	waveFormDiv: null,
	intervalId: null,
	playbackRate: 1,
	setPlaybackRate: (playbackRate) => {},
	zoom: 0,
	setZoom: (zoom) => {},
	isReady: false,
	setIsReady: (isReady) => {}
});

export function WaveSurferProvider ({children}) {
	const waveSurferRef = useRef(null);
	const waveFormDivRef = useRef(null);
	const intervalId = useRef(null);

	const [playbackRate, setPlaybackRate] = useState(1);
	const [zoom, setZoom] = useState(0);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (playbackRate <= 0) { // it won't play if playBackRate is 0
			clearInterval(intervalId.current);
		}
		else if (waveSurferRef.current) {
			// pause and play audio because of bug with incorrect cursor position in wavesurfer when changing playback rate
			const wasPlaying = waveSurferRef.current.isPlaying();
			if (wasPlaying) {
				waveSurferRef.current.pause();
			}

			waveSurferRef.current.setPlaybackRate(playbackRate);
			wasPlaying && waveSurferRef.current.play();
		}
	}, [playbackRate]);

	useEffect(() => {
		if (zoom === 0) {
			clearInterval(intervalId.current);
		}
	}, [zoom]);

	return (
		<waveSurferContext.Provider value={{
			waveSurfer: waveSurferRef,
			waveFormDiv: waveFormDivRef,
			intervalId,
			playbackRate,
			setPlaybackRate,
			zoom,
			setZoom,
			isReady,
			setIsReady
		}}>
			{children}
		</waveSurferContext.Provider>
	);
}
