import RegionsTableHead from "./RegionsTableHead";
import RegionRow from "./RegionRow";
import {useContext} from "react";
import {regionsContext} from "../../contexts/regions";

function RegionsTable() {
	const {regions} = useContext(regionsContext);

	return (
		<table className={"table table-light table-bordered"} >
			<caption className={"caption-top"}>Segments</caption>
			<RegionsTableHead />
			<tbody>
			{regions.map(value => {
				if (value.isReady) {
					return <RegionRow region={value} key={value.id}/>;
				}
				else {
					return  null;
				}
			})}
			</tbody>
		</table>
	);
}

export default RegionsTable;
