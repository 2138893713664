import {useContext} from "react";
import {regionsContext} from "../../../contexts/regions";

function Boolean({boolean, region}) {
	const {updateRegion} = useContext(regionsContext);

	function onChange(evt) {
		updateRegion({
			id: region.id,
			[boolean.name]: evt.target.checked
		});
	}

	const checked = region[boolean.name] || false;

	return (
		<input className="form-check-input" type="checkbox" checked={checked} onChange={onChange}/>
	);
}

export default Boolean;
